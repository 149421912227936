import { AiTwotoneBank } from "react-icons/ai";
import { FaChalkboardTeacher } from "react-icons/fa";
import { FaPersonChalkboard } from "react-icons/fa6";
import { HiPlusSm } from "react-icons/hi";
import { MdAutoGraph, MdOutlineMarkEmailRead, MdOutlineWorkOutline } from "react-icons/md";
import { RiUserSettingsLine } from "react-icons/ri";
import { TbUsers, TbUserScreen } from "react-icons/tb";
import { BsUiChecksGrid } from "react-icons/bs";
import { HiOutlineUserPlus } from "react-icons/hi2";



const allSideBarLinks = [
    {
        module_name: "Administrator",
        moduleIcon: <HiPlusSm />,
        subLinks: [
            {
                label: "Add New User",
                to: "/add-new-user",
                icon: <HiOutlineUserPlus size={"16px"} />
            },
            {
                label: "All Users",
                to: "/all-users",
                icon: <BsUiChecksGrid />
            }
        ]
    },
    {
        module_name: "Candidates",
        moduleIcon: <TbUsers />,
        subLinks: [
            {
                label: "Add New Candidate",
                to: "/add-new-candidate",
            },
            {
                label: "My Candidates",
                to: "/my-candidates",
            },
            {
                label: "All Candidates",
                to: "/all-candidates",
            }
        ]
    },
    {
        module_name: "Interviews",
        moduleIcon: <TbUserScreen />, // Use a different icon as necessary
        subLinks: [
            {
                label: "Schedule Interview",
                to: "/schedule-interview",
            },
            {
                label: "All Interviews",
                to: "/all-interviews",
            }
        ]
    },
    {
        module_name: "Offer",
        moduleIcon: <MdOutlineMarkEmailRead />, // Use a different icon as necessary
        subLinks: [
            {
                label: "Create Offer",
                to: "/create-offer",
            },
            {
                label: "All Offers",
                to: "/all-offers",
            }
        ]
    },
    {
        module_name: "Training",
        moduleIcon: <FaChalkboardTeacher />, // Use a different icon as necessary
        subLinks: [
            {
                label: "Assign Training",
                to: "/assign-training",
            },
            {
                label: "Training Records",
                to: "/training-records",
            }
        ]
    },
    {
        module_name: "On-Boarding",
        moduleIcon: <FaPersonChalkboard />, // Use a different icon as necessary
        subLinks: [
            {
                label: "On-Board New Employee",
                to: "/onboard-new-employee",
            },
            {
                label: "All On-Boardings",
                to: "/all-onboardings",
            }
        ]
    },
    {
        module_name: "Appraisal",
        moduleIcon: <MdAutoGraph />, // Use a different icon as necessary
        subLinks: [
            {
                label: "Start Appraisal",
                to: "/start-appraisal",
            },
            {
                label: "Appraisal Records",
                to: "/appraisal-records",
            }
        ]
    },
    {
        module_name: "Pay-Roll",
        moduleIcon: <AiTwotoneBank />, // Use a different icon as necessary
        subLinks: [
            {
                label: "Salary Management",
                to: "/salary-management",
            },
            {
                label: "Pay-Roll Records",
                to: "/payroll-records",
            }
        ]
    },
    // {
    //     module_name: "Exit",
    //     moduleIcon: <FaUsers />, // Use a different icon as necessary
    //     subLinks: [
    //         {
    //             label: "Process Exit",
    //             to: "/process-exit",
    //         },
    //         {
    //             label: "Exit Records",
    //             to: "/exit-records",
    //         }
    //     ]
    // },
    {
        module_name: "Jobs",
        moduleIcon: <MdOutlineWorkOutline />, // Use a different icon as necessary
        subLinks: [
            {
                label: "Post New Job",
                to: "/post-new-job",
            },
            {
                label: "All Jobs",
                to: "/all-jobs",
            }
        ]
    },
    {
        module_name: "My Profile",
        moduleIcon: <RiUserSettingsLine />,
        subLinks: [
            {
                label: "View Profile",
                to: "/view-profile",
            },
            {
                label: "Edit Profile",
                to: "/edit-profile",
            }
        ]
    }
];

export default allSideBarLinks;