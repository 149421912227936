const allModules = [
    {
        "module_name": "Administrator",
        "c": 0,
        "r": 0,
        "u": 0,
        "d": 0
    },
    {
        "module_name": "Jobs",
        "c": 0,
        "r": 0,
        "u": 0,
        "d": 0
    },
    {
        "module_name": "Candidates",
        "c": 0,
        "r": 0,
        "u": 0,
        "d": 0
    },
    {
        "module_name": "Interviews",
        "c": 0,
        "r": 0,
        "u": 0,
        "d": 0
    },
    {
        "module_name": "Offer",
        "c": 0,
        "r": 0,
        "u": 0,
        "d": 0
    },
    {
        "module_name": "On-Boarding",
        "c": 0,
        "r": 0,
        "u": 0,
        "d": 0
    },
    {
        "module_name": "Training",
        "c": 0,
        "r": 0,
        "u": 0,
        "d": 0
    },
    {
        "module_name": "Appraisal",
        "c": 0,
        "r": 0,
        "u": 0,
        "d": 0
    },
    {
        "module_name": "My Profile",
        "c": 0,
        "r": 0,
        "u": 0,
        "d": 0
    }
]

export default allModules;