import React from "react";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
    const navigate = useNavigate()
    return (
        <section className="component-rendering-tranistion p-4 h-[40em] flex items-center justify-center text-xl">
            <div className="text-center">
                <h1 className="text-2xl text-indigo-700">Error 404</h1>
                <h1 className="text-2xl text-indigo-700">Page Not Found</h1>
                <div className="mt-10"><button className="p-2 px-5 bg-gray-50 border rounded-full" onClick={() => navigate(-1)}>Go Back</button></div>
                {/* <h1>or</h1>
                <h1>Page Under developement</h1> */}
            </div>
        </section>
    );
}

export default PageNotFound;