import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { IoIosArrowUp } from "react-icons/io";
import { AiOutlineLoading, AiOutlineLoading3Quarters } from 'react-icons/ai';
import { Link, NavLink, useLocation, useParams } from 'react-router-dom';
import { MdWorkOutline, MdCall, MdEdit, MdDeleteOutline } from "react-icons/md";

function AllCandidates(props) {
    const apiUrl = process.env.REACT_APP_API;
    // const location = props.location;
    const location = useLocation()
    const [activeButton, setActiveButton] = useState("all");
    const [allCandidates, setAllCandidates] = useState([]);
    const [loading, setLoading] = useState(false); // Loading state
    const [dropDown, setDropDown] = useState(false);
    const user = localStorage.getItem("userDetails") ? JSON.parse(localStorage.getItem("userDetails")) : null
    document.title = "All Candidates | HRMS"

    // Handle button click to toggle dropdown
    const handleButtonClick = (e) => {
        setDropDown(!dropDown); // Toggles the dropdown
    };

    useEffect(() => {
        let createdBy = user !== null ? user.username : null
        if (props.activeButton === "my candidates") {
            setLoading(true);
            axios.post(`${apiUrl}/my-candidates`, { "created_by": createdBy }, { withCredentials: true })
                .then((res) => {
                    setAllCandidates(res.data.candidates);
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    setLoading(false);
                });
        }

        else if (props.activeButton === "all candidates") {
            if (props.userRole === "Manager") {
                axios.get(`${apiUrl}/hierarchical_candidate_list/${user.id}`, { withCredentials: true })
                    .then((res) => {
                        setAllCandidates(res.data.candidates);
                    })
                    .catch((err) => {
                        console.log(err);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
                setLoading(true);
            }
            else {
                axios.get(`${apiUrl}/all-candidates`, { withCredentials: true })
                    .then((res) => {
                        setAllCandidates(res.data.candidates);
                    })
                    .catch((err) => {
                        console.log(err);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
                setLoading(true);
            }
        }

    }, [props.activeButton, location]);

    return (
        <div className='h-full overflow-auto'>
            <div className='grid'>
                {/* Check if loading is true, display loading spinner */}
                {loading ? (
                    <div className='flex justify-center gap-4 items-center h-20'>
                        <AiOutlineLoading className="reload-rounding text-indigo-800" size={"18px"} />
                        <span>Loading...</span>
                    </div>
                ) : (
                    // If not loading, show candidate list or a message if empty
                    allCandidates.length > 0 ? (
                        allCandidates.map((value, index) => {
                            console.log(value)
                            return (
                                <div key={index} className={`relative flex p-2 h-20 border-b transition-small ${location.pathname == `/candidates/view/${value.candidate_id}` ? "bg-indigo-100" : "hover:shadow-xl hover:bg-gray-50"}`}>
                                    <div className='min-w-12 flex justify-center items-center -mt-6 -ml-2'>
                                        <input type="checkbox" />
                                    </div>
                                    <div className='w-full'>
                                        <div className='flex items-center justify-between'>
                                            <NavLink to={`/candidates/view/${value.candidate_id}`} className={'block mt-[6px] hover:text-indigo-700 hover:underline'}>
                                                {`${value.title} ${value.first_name} ${value.last_name}`}
                                            </NavLink>
                                            <span className={`inline-block text-xs p-1.5 px-3 border rounded-full ${value.status === "Follow-up" ? "text-orange-500 border-orange-300 bg-orange-100" : value.status === "Active" ? "text-green-500 border-green-300 bg-green-50" : value.status === "Shortlisted" ? "text-lime-500 border-lime-300 bg-lime-50" : value.status === "OnBoard" ? "text-yellow-500 border-yellow-300 bg-yellow-50" : value.status === "Training" ? "text-pink-500 border-pink-300 bg-pink-50" : value.status === "Live" ? "text-indigo-500 border-indigo-300 bg-indigo-50" : "text-red-500 border-red-300 bg-red-50"}`}>{value.status ? value.status : "No Status"}</span>
                                        </div>
                                        <div className='mt-1'>
                                            <span className='inline-flex items-center gap-2 w-40'><MdCall /> {value.contact_number}</span>
                                            <span className='inline-flex items-center gap-2'><MdWorkOutline />{value.experience_years >= 1 || value.experience_months >= 1 ? `${value.experience_years && value.experience_years > 0 ? `${value.experience_years} Yrs.` : ""} ${value.experience_months && value.experience_months > 0 ? `${value.experience_months} Months` : ""}` : "Fresher"}</span>
                                        </div>
                                    </div>

                                    {/* Action Area - Initially hidden, visible on hover */}
                                    <span className='absolute inline-flex gap-2 items-center p-2 top-0 right-0 transition-opacity duration-200 opacity-0 hover-parent-hover:opacity-100'>
                                        <span className='w-8 h-8 inline-flex items-center justify-center rounded-lg cursor-pointer bg-gray-100 hover:bg-gray-200'>
                                            <MdEdit />
                                        </span>
                                        <span className='w-8 h-8 inline-flex items-center justify-center rounded-lg cursor-pointer bg-red-100 hover:bg-red-500 hover:text-white'>
                                            <MdDeleteOutline />
                                        </span>
                                    </span>
                                </div>
                            )
                        })
                    ) : (
                        <div className='p-4'>No Candidates Found!</div>
                    )
                )}
            </div>
        </div>
    );
}

export default AllCandidates;