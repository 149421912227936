import axios from "axios"
import { useDispatch, useSelector } from "react-redux"
import { resetModules, updateModules } from "../redux/reducers/auth_slice";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const useUpdateUser = () => {
    const apiUrl = process.env.REACT_APP_API;
    let userModules = useSelector((state) => state.userModules);
    let dispatch = useDispatch();
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const updateUser = (user, userid) => {
        const updatedModules = [];
        setLoading(true)
        userModules.map((currentModule, moduleKey) => {
            if (currentModule.moduleSelected || currentModule.module_status) {
                updatedModules.push(currentModule);
            }
        })

        // Api Call to update user
        axios.post(`${apiUrl}/userdata/update/${userid}`, {
            "title": user.title,
            "first_name": user.firstName,
            "middle_name": user.middleName,
            "last_name": user.lastName,
            "gender": user.gender,
            "dob": user.dateOfBirth,
            "email": user.emailId,
            "mobile": user.mobile,
            "date_of_joining": user.dateOfJoining,
            "employee_id": user.employeeId,
            "designation": user.designation,
            "status": user.status,
            "department": user.department,
            "user_type": user.userType,
            "role": user.role,
            "reporting_to": user.reportingTo,
            "created_by": "----",
            "modules": updatedModules
        }, {withCredentials: true})
            .then((response) => {
                dispatch(resetModules());
                setSuccess(true);
                setLoading(false)
                setError(false)
                setTimeout(() => {
                    setSuccess(false)
                    setLoading(false)
                    setError(false)
                }, 5000)
            })
            .catch((err) => {
                setSuccess(false);
                setLoading(false);
                setError(true);
                setErrorMessage(err.response.data.error);
                console.log(err)
                setTimeout(() => {
                    setSuccess(false)
                    setLoading(false)
                    setError(false);
                    setErrorMessage("");
                }, 5000)
            })
    }
    return { updateUser, success, loading, error, errorMessage }
}

export default useUpdateUser;