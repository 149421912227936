import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineLoading, AiOutlineLoading3Quarters } from "react-icons/ai";
import { FaEye } from 'react-icons/fa';
import { MdDelete } from "react-icons/md";

function AllUsers() {
    const apiUrl = process.env.REACT_APP_API;
    const [allUser, setAllUsers] = useState([]);

    useEffect(() => {
        // Fetching all users on component mount
        axios.get(`${apiUrl}/user/all-users`, {withCredentials: true})
            .then((response) => {
                setAllUsers(response.data.data);
                setLoading(false)
                console.log(loading)
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const [loading, setLoading] = useState(true)
    const handleDelete = (userId) => {
        // Show confirmation dialog
        const confirmDelete = window.confirm("Are you sure you want to delete this user?");
        if (confirmDelete) {
            // Call the API to delete the user if confirmed
            axios.get(`${apiUrl}/delete-user/${userId}`, {withCredentials: true})
                .then(() => {
                    console.log("User deleted successfully");
                    // Optionally, remove the deleted user from the state
                    setAllUsers(allUser.filter(user => user.id !== userId));
                })
                .catch((err) => console.log(err));
        }
    };

    if (loading) {
        return (
            <div className='h-full w-full flex items-center justify-center bg-gray-100'>
                <AiOutlineLoading className='reload-rounding mr-4' size={"20px"} />Loading...
            </div>
        )
    }

    return (
        <div className='overflow-auto'>
            <div className='flex w-full'>
                <div className='min-w-60 font-bold p-3 border-b'>View User Profile</div>
                <div className='w-full min-w-60 font-bold p-3 border-b'>Employee ID</div>
                <div className='w-full min-w-60 font-bold p-3 border-b'>Name</div>
                <div className='w-full min-w-60 font-bold p-3 border-b'>Username</div>
                <div className='w-full min-w-60 font-bold p-3 border-b'>Gender</div>
                <div className='w-full min-w-60 font-bold p-3 border-b'>DOB</div>
                <div className='w-full min-w-60 font-bold p-3 border-b'>Email</div>
                <div className='min-w-40 font-bold p-3 border-b'>Delete Profile</div>
                {/* <div className='w-full min-w-60 font-bold p-3 border'>Mobile</div>
                <div className='w-full min-w-60 font-bold p-3 border'>Date of Joining</div>
                <div className='w-full min-w-60 font-bold p-3 border'>Designation</div>
                <div className='w-full min-w-60 font-bold p-3 border'>Status</div>
                <div className='w-full min-w-60 font-bold p-3 border'>Department</div>
                <div className='w-full min-w-60 font-bold p-3 border'>User Type</div>
                <div className='w-full min-w-60 font-bold p-3 border'>Role</div>
                <div className='w-full min-w-60 font-bold p-3 border'>Reporting To</div> */}
            </div>

            {
                allUser.map((user) => (
                    <div className='flex w-full hover:bg-indigo-50' key={user.id}> {/* Add key prop for unique identification */}
                        <div className='min-w-60 p-1 px-3 flex items-center'>
                            {/* {user.employee_id} */}
                            <Link to={`/user/${user.id}`} className='inline-flex items-center gap-2 justify-center border p-1.5 px-3 text-sm rounded-full hover:bg-indigo-700 hover:text-white'><FaEye /> View Profile</Link>
                        </div>
                        <div className='w-full min-w-60 p-1 px-3 flex justify-between items-center'>
                            {user.employee_id}
                            {/* <Link to={`/user/${user.id}`} className='inline-flex items-center gap-2 justify-center border p-1.5 px-3 text-sm rounded-full hover:bg-indigo-700 hover:text-white'><FaEye /> View Profile</Link> */}
                        </div>
                        <div className='w-full min-w-60 p-3'>{`${user.title} ${user.first_name} ${user.middle_name}`}</div>
                        <div className='w-full min-w-60 p-3'>{user.username}</div>
                        <div className='w-full min-w-60 p-3'>{user.gender}</div>
                        <div className='w-full min-w-60 p-3'>{user.dob}</div>
                        <div className='w-full min-w-60 p-3'>{user.email}</div>
                        <div className='min-w-40 p-3 flex justify-between'>
                            {/* <Link to={`/user/${user.id}`} className='p-1 rounded-full px-4 bg-green-500 text-white'>View</Link> */}
                            <button type='button' className='inline-flex items-center gap-2 justify-center border p-1.5 px-3 text-sm rounded-full hover:bg-red-600 hover:text-white'
                                onClick={() => handleDelete(user.id)} // Call handleDelete with user id
                            >
                                <MdDelete /> Delete
                            </button>
                        </div>
                        {/* <div className='w-full min-w-60 p-3 border'>{user.mobile}</div>
                        <div className='w-full min-w-60 p-3 border'>{user.date_of_joining}</div>
                        <div className='w-full min-w-60 p-3 border'>{user.designation}</div>
                        <div className='w-full min-w-60 p-3 border'>{user.status}</div>
                        <div className='w-full min-w-60 p-3 border'>{user.department}</div>
                        <div className='w-full min-w-60 p-3 border'>{user.user_type}</div>
                        <div className='w-full min-w-60 p-3 border'>{user.role}</div>
                        <div className='w-full min-w-60 p-3 border'>{user.reporting_to}</div> */}
                    </div>
                ))
            }
        </div>
    );
}

export default AllUsers;
