import axios from "axios";
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userLoggedIn } from "../redux/reducers/auth_slice";
import useSidebarAuth from "./useSidebarLink";

const useLogin = () => {
    const apiUrl = process.env.REACT_APP_API;
    const [isUserLoggedin, setIsUserLoggedin] = useState();
    const [loginLoading, setLoginLoading] = useState(false)
    const [loginError, setLoginError] = useState(false)
    const [loginErrorValues, setLoginErrorValues] = useState({ status: null, value: false })

    const { ValidateSidebar } = useSidebarAuth();
    const dispatch = useDispatch();

    const Navigate = useNavigate();
    const login = (username, password) => {
        const credentials = {
            "username": username,
            "password": password
        }
        setLoginLoading(true);
        axios.post(`${apiUrl}/login`, credentials, {withCredentials: true})
            .then((response) => {
                if (response.data.success) {
                    setIsUserLoggedin(true);
                    setLoginError(false);
                    setLoginLoading(false);

                    Navigate('/');
                    dispatch(
                        userLoggedIn(
                            {
                                status: true,
                                access: "admin",
                                username: response.data.user.username,
                                data: response.data
                            }
                        )
                    );
                    ValidateSidebar();
                }
            })
            .catch((err) => {
                console.log(err);
                setLoginError(true);
                setIsUserLoggedin(false);
                setLoginLoading(false);

                setTimeout(() => {
                    setIsUserLoggedin(null)
                }, 3000);


                // If User is unauthorised
                if (err.status === '401') {
                    setTimeout(() => {
                        setIsUserLoggedin(null)
                    }, 2900)
                }
                else if (err.status === 500) {
                    setLoginErrorValues({ status: 500, value: true })
                    setTimeout(() => {
                        setLoginErrorValues({ status: null, value: false })
                    }, 3000)
                }
            });
    }


    return { login, isUserLoggedin, loginLoading, loginError, loginErrorValues }
}

export default useLogin;