import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { HiOutlineCalendarDays } from "react-icons/hi2";
import { IoTimeOutline } from "react-icons/io5";
import { FaUserTie } from "react-icons/fa";
import { AiOutlineLoading } from 'react-icons/ai';
function AllInterviews() {
    const apiUrl = process.env.REACT_APP_API;
    const [allUsers, setAllUsers] = useState([]);
    const [allInterviews, setAllInterviews] = useState([]);
    const [allJobs, setAllJobs] = useState([]);
    const [isLoadingUsers, setIsLoadingUsers] = useState(true);
    const [isLoadingInterviews, setIsLoadingInterviews] = useState(true);
    const [isLoadingJobs, setIsLoadingJobs] = useState(true);

    let location = useLocation();

    useEffect(() => {
        // All Users Api
        axios.get(`${apiUrl}/user/all-users`, {withCredentials: true})
            .then((res) => {
                setAllUsers(res.data.data);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => setIsLoadingUsers(false));

        // All Interviews Api
        axios.get(`${apiUrl}/all-interviews`, {withCredentials: true})
            .then((res) => {
                setAllInterviews(res.data.data);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => setIsLoadingInterviews(false));

        // All Jobs Api
        axios.get(`${apiUrl}/display_jobs`, {withCredentials: true})
            .then((res) => {
                setAllJobs(res.data.jobs);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => setIsLoadingJobs(false));
    }, []);

    return (
        <div className='h-full border-r overflow-auto'>
            {
                isLoadingInterviews || isLoadingJobs || isLoadingUsers ?
                    <div className='p-4 h-full w-full flex gap-4 justify-center items-center'>
                        <AiOutlineLoading className="reload-rounding text-indigo-800" size={"18px"} />
                        <span>Loading...</span>
                    </div> :
                    allInterviews.length > 0 ?
                        allInterviews.map((interview, index) => {
                            return (
                                <div key={index} className={`h-20 p-3 border-b transition-small hover:shadow-xl ${location.pathname === `/interviews/view/${interview.id}` ? "bg-indigo-100" : ""}`}>
                                    <h1 className='px-3 flex items-center'>
                                        <span><input type="checkbox" className='h-4 w-4' /></span>
                                        <Link to={`view/${interview.id}`} className='block -mt-1 ml-4 hover:underline hover:text-indigo-700'>
                                            <span>
                                                {
                                                    allJobs.length > 0 ?
                                                        allJobs.map((job, jobIndex) => {
                                                            if (job.id === interview.job_id) {
                                                                return (
                                                                    <span key={jobIndex}>{job.job_title === "" ? "Job Title" : job.job_title}</span>
                                                                );
                                                            }
                                                        }) : "---"
                                                }
                                            </span>
                                        </Link>
                                    </h1>

                                    <div className='ml-[38px] font-light flex items-center text-sm'>
                                        <span className='w-full inline-flex items-center gap-2 text-sm'>
                                            <HiOutlineCalendarDays size={"14px"} />
                                            {
                                                new Date(interview.interview_date).toLocaleDateString('en-US', {
                                                    day: 'numeric',
                                                    month: 'short',
                                                    year: 'numeric'
                                                })
                                            }
                                        </span>

                                        <span className='w-full inline-flex items-center gap-2 ml-4 text-sm'>
                                            <IoTimeOutline />
                                            {
                                                (() => {
                                                    const [hours, minutes] = interview.interview_time.split(':');
                                                    const date = new Date();
                                                    date.setHours(hours, minutes);

                                                    return date.toLocaleString('en-US', {
                                                        hour: 'numeric',
                                                        minute: 'numeric',
                                                        hour12: true
                                                    });
                                                })()
                                            }
                                        </span>

                                        <span className='w-full inline-flex items-center gap-2 ml-4 text-sm'>
                                            <FaUserTie />
                                            {
                                                allUsers.length > 0 ?
                                                    allUsers.map((value, index) => {
                                                        if (value.id == interview.interviewer) {
                                                            return (
                                                                <div key={index}>{`${value.first_name}`}</div>
                                                            );
                                                        }
                                                    }) : "o"
                                            }
                                        </span>
                                    </div>
                                </div>
                            );
                        }) : <div className='p-4'>No Interviews Found!</div>
            }
        </div>
    );
}

export default AllInterviews;