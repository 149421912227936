import { createSlice } from "@reduxjs/toolkit";
import allModules from "../../components/pages/User/AllModules";

const initialState = {
    user_auth: {
        loggedIn: false,
        userType: "admin",
        username: null,
        loginTimestamp: null
    },
    moduleSelection: {
        invalid: [],
    },
    userModules: allModules,
};

const authSlice = createSlice({
    name: "authSlice",
    initialState: initialState,
    reducers: {
        userLoggedIn: (state, action) => {
            const userDetails = {
                loggedIn: action.payload.status,
                userType: action.payload.access,
                username: action.payload.username,
                logoutTimestamp: Date.now()
            };
            localStorage.setItem("user", JSON.stringify(userDetails));
            localStorage.setItem("userDetails", JSON.stringify(action.payload.data.user));
            console.log(action.payload.data.user)
            state.user_auth = userDetails;
        },
        userLoggedOut: (state) => {
            const userDetails = {
                loggedIn: false,
                userType: null,
            };
            localStorage.setItem("user", JSON.stringify(userDetails));
            state.user_auth = userDetails;
        },

        // Assign Modules
        updateModules: (state, action) => {
            const updatedModules = [];
            state.userModules.map((module, moduleKey) => {
                updatedModules.push(module);
                if (module.module_name === action.payload.module_name) {
                    updatedModules[moduleKey] = action.payload;
                }
            })
            state.userModules = updatedModules;
        },


        resetModules: (state, action) => {
            state.userModules = allModules;
        },

        switchInvalidation_modules: (state, action) => {
            const status = action.payload.status
            const module = action.payload.module
            if (status) {
                if (!state.moduleSelection.invalid.includes(module)) {
                    state.moduleSelection.invalid.push(module);
                }
            }
            else if (!status) {
                let updatedError = []
                if (state.moduleSelection.invalid.includes(module)) {
                    state.moduleSelection.invalid.map((value, key) => {
                        if (value !== module) {
                            updatedError.push(value);
                        }
                    });
                }
                state.moduleSelection.invalid = updatedError
            }
        },
    }
});










// Export the actions generated by createSlice
export const { userLoggedIn, userLoggedOut, updateModules, resetModules, switchInvalidation_modules, setAuthSidebar } = authSlice.actions;

// Export the reducer to be added to the store
export default authSlice.reducer;