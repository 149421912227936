import { useEffect, useState } from "react";
import useRegisterUser from "../../../helpers/useRegisterUser";
import allModules from "./AllModules";
import { useSelector } from "react-redux";
import UserModule from "../../commons/UserModule";

// Icons
import { GoEyeClosed, GoEye } from "react-icons/go";
import axios from "axios";
import { AiOutlineLoading } from "react-icons/ai";

export default function NewUser() {
    const apiUrl = process.env.REACT_APP_API;
    const warnDefault = useSelector((state) => state.moduleSelection.invalid.length);
    const [mobileError, setMobileError] = useState(false);
    const [submitError, setsubmitError] = useState(false)

    const [passwordConfirm, setPasswordConfirm] = useState(true);

    const [user, setUser] = useState({
        title: '',
        firstName: '',
        middleName: '',
        lastName: '',
        gender: '',
        dateOfJoining: '',
        username: '',
        password: '',
        confirmPassword: '',
        emailId: '',
        status: '',
        mobile: '',
        dateOfBirth: '',
        reportingTo: '',
        employeeId: '',
        userType: '',
        designation: '',
        department: '',
        role: '',
    });

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const { errorMessage, registerEvents, registerUser } = useRegisterUser();

    const register = (e) => {
        e.preventDefault();
        if (user.password !== user.confirmPassword) {
            setPasswordConfirm(false);
            alert("Passwords do not match!"); // Show alert if passwords do not match
            return; // Prevent form submission
        } else {
            setPasswordConfirm(true);
            console.log(`Password Matched Successfully!`);
        }
        registerUser(user);
    };

    // Email Validation
    const [emailError, setEmailError] = useState('');

    const handleEmailChange = (e) => {
        const value = e.target.value;

        // Basic email validation regex (also prevents spam)
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const invalidCharactersPattern = /[!#$%^&*()_+={}\[\]:;"'<>,?\/\\|`~]/;

        // Set the email in the state
        setUser((values) => ({ ...values, emailId: value }));

        // Validate the email and set error message
        if (value) {
            if (invalidCharactersPattern.test(value)) {
                setsubmitError(true)
                setEmailError('Email should not contain special characters like !@#$%^&*()_+ etc.');
            } else if (!emailPattern.test(value)) {
                setsubmitError(true)
                setEmailError('Please enter a valid email address.');
            } else {
                setsubmitError(false)
                setEmailError('');
            }
        } else {
            setsubmitError(false)
            setEmailError('');
        }
    };

    // Password Validation
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');

    const validatePassword = (password, confirmPassword) => {
        if (password.length < 6) {
            setPasswordError("Password must be at least 6 characters.");
        } else {
            setPasswordError('');
        }

        if (confirmPassword && confirmPassword !== password) {
            setConfirmPasswordError("Passwords do not match.");
        } else {
            setConfirmPasswordError('');
        }
    };

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setUser((values) => ({ ...values, password: newPassword }));
        validatePassword(newPassword, user.confirmPassword);
    };

    const handleConfirmPasswordChange = (e) => {
        const newConfirmPassword = e.target.value;
        setUser((values) => ({ ...values, confirmPassword: newConfirmPassword }));
        validatePassword(user.password, newConfirmPassword);
    };




    // Reporting To Users
    const [reportingToUsers, setReportingToUsers] = useState([]);
    useEffect(() => {
        axios.get(`${apiUrl}/reporting-to-users`, { withCredentials: true })
            .then((res) => {
                console.log(res.data);
                setReportingToUsers(res.data.data);
            })
            .catch((err) => {
                console.log(err)
            })
    }, []);

    return (
        <section className="p-8 component-rendering-tranistion bg-gray-100">
            {/* Alerts */}
            <div className={`fixed top-32 bg-green-100 shadow-xl border border-green-500 p-4 min-h-10 w-96 transition-small ${registerEvents.success ? 'right-10' : '-right-[110%]'}`}>
                User Created Successfully
            </div>
            <div className={`fixed top-32 bg-red-100 shadow-xl border border-red-500 p-4 min-h-10 w-96 transition-small ${registerEvents.error ? 'right-10' : '-right-[110%]'}`}>
                Sorry! Can't Create User, <br /> <span className="text-red-500 font-semibold">{errorMessage}</span>
            </div>
            <div className={`fixed top-0 right-0 z-[100] backdrop-blur-xl p-4 h-screen flex gap-4 items-center justify-center w-screen transition-small ${registerEvents.loading ? 'flex' : 'hidden'}`}>
                <AiOutlineLoading className="reload-rounding" />
                Creating User...
            </div>

            <h1 className="text-4xl font-semibold">Create New User</h1>

            <form onSubmit={register} className="w-full mt-10">
                <div className="grid grid-cols-5 gap-8 p-8 pb-10 bg-white shadow-xl rounded-xl">
                    <div>
                        <label htmlFor="#" className="font-semibold inline-block p-4 pl-0">Title <span className="text-red-500">*</span></label>
                        <select required className="primary-input bg-gray-50" defaultValue={""}
                            onChange={(e) => setUser((values) => ({ ...values, title: e.target.value }))} >
                            <option disabled={true} value={""}>-- Select --</option>
                            <option value="Mr.">Mr.</option>
                            <option value="Ms.">Ms.</option>
                        </select>
                    </div>

                    <div>
                        <label htmlFor="#" className="font-semibold inline-block p-4 pl-0">First Name <span className="text-red-500">*</span></label>
                        <input type="text" className="primary-input" required placeholder="First Name" name="" id=""
                            onChange={(e) => setUser((values) => ({ ...values, firstName: e.target.value }))}
                        />
                    </div>

                    <div>
                        <label htmlFor="#" className="font-semibold inline-block p-4 pl-0">Middle Name</label>
                        <input type="text" className="primary-input" placeholder="Middle Name" name="" id=""
                            onChange={(e) => setUser((values) => ({ ...values, middleName: e.target.value }))}
                        />
                    </div>

                    <div>
                        <label htmlFor="#" className="font-semibold inline-block p-4 pl-0">Last Name <span className="text-red-500">*</span></label>
                        <input type="text" className="primary-input" required placeholder="Last Name" name="" id=""
                            onChange={(e) => setUser((values) => ({ ...values, lastName: e.target.value }))}
                        />
                    </div>

                    <div>
                        <label htmlFor="#" className="font-semibold inline-block p-4 pl-0">Gender <span className="text-red-500">*</span></label>
                        <select className="primary-input" required defaultValue={""}
                            onChange={(e) => setUser((values) => ({ ...values, gender: e.target.value }))}
                        >
                            <option disabled={true} value={""}>-- Select --</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                        </select>
                    </div>

                    <div>
                        <label htmlFor="#" className="font-semibold inline-block p-4 pl-0">Date of Joining <span className="text-red-500">*</span></label>
                        <input type="date" className="primary-input" name="" id="" required
                            onChange={(e) => setUser((values) => ({ ...values, dateOfJoining: e.target.value }))}
                        />
                    </div>

                    <div>
                        <label htmlFor="#" className="font-semibold inline-block p-4 pl-0">Username <span className="text-red-500">*</span></label>
                        <input
                            required
                            type="text"
                            className="primary-input"
                            placeholder="Username"
                            name=""
                            id=""
                            value={user.username} // Controlled input
                            onChange={(e) => {
                                const value = e.target.value;
                                // Capitalize the first letter and keep the rest unchanged
                                const capitalizedUsername = value.charAt(0).toUpperCase() + value.slice(1);
                                setUser((values) => ({ ...values, username: capitalizedUsername }));
                            }}
                        />
                    </div>

                    <div>
                        <label htmlFor="password" className="font-semibold inline-block p-4 pl-0">Password <span className="text-red-500">*</span></label>
                        <div className="relative">
                            <input
                                required
                                type={showPassword ? "text" : "password"}
                                className="primary-input"
                                placeholder="Password"
                                value={user.password}
                                onChange={handlePasswordChange}
                            />
                            <button
                                type="button"
                                className="absolute right-4 top-1/2 transform -translate-y-1/2"
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                {showPassword ? <GoEye size={'17px'} /> : <GoEyeClosed size={'17px'} />}
                            </button>
                        </div>
                        {passwordError && <p className="text-red-500">{passwordError}</p>}
                    </div>

                    <div>
                        <label htmlFor="confirmPassword" className="font-semibold inline-block p-4 pl-0">Confirm Password <span className="text-red-500">*</span></label>
                        <div className="relative">
                            <input
                                required
                                type={showConfirmPassword ? "text" : "password"}
                                className="primary-input"
                                placeholder="Confirm Password"
                                value={user.confirmPassword}
                                onChange={handleConfirmPasswordChange}
                            />
                            <button
                                type="button"
                                className="absolute right-4 top-1/2 transform -translate-y-1/2"
                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                            >
                                {showConfirmPassword ? <GoEye size={'17px'} /> : <GoEyeClosed size={'17px'} />}
                            </button>
                        </div>
                        {confirmPasswordError && <p className="text-red-500">{confirmPasswordError}</p>}
                    </div>

                    <div>
                        <label htmlFor="#" className="font-semibold inline-block p-4 pl-0">Email ID <span className="text-red-500">*</span></label>
                        <input
                            type="email"
                            required
                            className="primary-input"
                            placeholder="Email ID"
                            value={user.emailId} // Controlled input
                            onChange={handleEmailChange}
                        />
                        {emailError && (
                            <p className="text-red-500 text-sm mt-1">{emailError}</p>
                        )}
                    </div>

                    <div>
                        <label htmlFor="mobile" className="font-semibold inline-block p-4 pl-0">Mobile <span className="text-red-500">*</span></label>
                        <input
                            type="tel"
                            required
                            className="primary-input"
                            placeholder="Mobile Number"
                            id="mobile"
                            value={user.mobile} // Controlled input
                            onChange={(e) => {
                                const value = e.target.value;

                                const sanitizedValue = value.replace(/\D/g, '');
                                setUser((values) => ({ ...values, mobile: sanitizedValue }));
                                if (sanitizedValue.length > 0 && sanitizedValue.length < 10) {
                                    setMobileError(true);
                                } else if (sanitizedValue.length === 10) {
                                    setMobileError(false);
                                } else if (sanitizedValue.length > 10) {
                                    setMobileError(true);
                                } else if (sanitizedValue > 1999999999) {
                                    setMobileError(true);
                                }
                                else {
                                    setMobileError(false)
                                }
                            }}
                        />
                        {mobileError ? <p className="text-red-500 text-sm mt-1">Inavlid Mobile Number</p> : ''} {/* Display error message */}
                    </div>


                    <div>
                        <label htmlFor="#" className="font-semibold inline-block p-4 pl-0">Date of birth</label>
                        <input type="date" className="primary-input" placeholder="Date of Birth" name="" id=""
                            onChange={(e) => setUser((values) => ({ ...values, dateOfBirth: e.target.value }))}
                        />
                    </div>

                    <div>
                        <label htmlFor="#" className="font-semibold inline-block p-4 pl-0">Reporting to <span className="text-red-500">*</span></label>
                        <select className="primary-input"
                            required defaultValue={""}
                            onChange={(e) => setUser((values) => ({ ...values, reportingTo: e.target.value }))}
                        >
                            <option disabled={true} value={""}>-- Select --</option>
                            {/* <option value="Akram">Akram</option>
                            <option value="Vivek Singh">Vivek Singh</option> */}
                            {
                                reportingToUsers.length > 0 ?
                                    reportingToUsers.map((value, index) => {
                                        return (
                                            <option value={value.username}>{value.username}</option>
                                        )
                                    }) : ""
                            }
                        </select>
                    </div>

                    <div>
                        <label htmlFor="#" className="font-semibold inline-block p-4 pl-0">Employee ID <span className="text-red-500">*</span></label>
                        <input type="text" className="primary-input" required placeholder="Emploayee ID" name="" id=""
                            onChange={(e) => setUser((values) => ({ ...values, employeeId: e.target.value }))}
                        />
                    </div>

                    <div>
                        <label htmlFor="#" className="font-semibold inline-block p-4 pl-0">Status <span className="text-red-500">*</span></label>
                        <select className="primary-input"
                            required defaultValue={""}
                            onChange={(e) => setUser((values) => ({ ...values, status: e.target.value }))}
                        >
                            <option disabled={true} value={""}>-- Select --</option>
                            <option value="Active">Active</option>
                            <option value="Inavtive">Inactive</option>
                        </select>
                    </div>

                    <div>
                        <label htmlFor="#" className="font-semibold inline-block p-4 pl-0">User Type <span className="text-red-500">*</span></label>
                        <select className="primary-input" required defaultValue={""}
                            onChange={(e) => setUser((values) => ({ ...values, userType: e.target.value }))}
                        >
                            <option value="" disabled={true}>-- Select User Type --</option>

                            <option value="Super Admin">Super Admin</option>
                            <option value="Admin">Admin</option>
                            <option value="User">User</option>
                        </select>
                    </div>

                    <div>
                        <label htmlFor="#" className="font-semibold inline-block p-4 pl-0">Role <span className="text-red-500">*</span></label>
                        <select className="primary-input"
                            required defaultValue={""}
                            onChange={(e) => setUser((values) => ({ ...values, role: e.target.value }))}
                        >
                            <option value="" disabled={true}>-- Select --</option>
                            <option value="Executive">Executive</option>
                            <option value="Manager">Manager</option>
                            <option value="Director">Director</option>
                        </select>
                    </div>

                    <div>
                        <label htmlFor="#" className="font-semibold inline-block p-4 pl-0">Designation <span className="text-red-500">*</span></label>
                        <select className="primary-input"
                            required defaultValue={""}
                            onChange={(e) => setUser((values) => ({ ...values, designation: e.target.value }))}
                        >
                            <option value="" disabled={true}>-- Select Designation --</option>
                            <option value="Recruiter - Intern">Recruiter - Intern</option>
                            <option value="Recruiter">Recruiter</option>
                            <option value="Sr. Recruiter">Sr. Recruiter</option>
                            <option value="HR Manager">HR Manager</option>
                            <option value="HR Diretor">HR Diretor</option>
                        </select>
                    </div>

                    <div>
                        <label htmlFor="#" className="font-semibold inline-block p-4 pl-0">Department <span className="text-red-500">*</span></label>
                        <select className="primary-input"
                            required defaultValue={""}
                            onChange={(e) => setUser((values) => ({ ...values, department: e.target.value }))}
                        >
                            <option value="" disabled={true}>-- Select Department --</option>

                            <option value="Human Resource">Human Resource</option>
                            <option value="Others">Others</option>
                        </select>
                    </div>
                </div>


                <div className="mt-10 p-8 bg-white rounded-xl shadow-xl">
                    <h1 className="text-xl font-bold">Select Permitted Module with CRUD Permissions</h1>
                    <div className="grid gap-2 grid-cols-2">
                        {
                            allModules.map((currentModule, moduleKey) => {
                                return (
                                    <UserModule
                                        key={moduleKey}
                                        id={`module-${currentModule.module_name}`}
                                        name={currentModule.module_name}
                                    />
                                )
                            })
                        }
                    </div>
                </div>


                <div className="flex items-center justify-end mt-10">
                    <div className="w-1/4 pl-4 relative">
                        <button
                            className="primary-button justify-center"
                            disabled={mobileError || submitError || warnDefault > 0 || registerEvents.loading} // Simplified condition
                            style={{
                                opacity: mobileError || submitError || registerEvents.loading || warnDefault > 0 ? 0.8 : 1,
                                cursor: mobileError || submitError || registerEvents.loading || warnDefault > 0 ? 'not-allowed' : 'pointer'
                            }}
                        >
                            Create User
                        </button>
                        {/* <span className="inline-block">
                            {submitError ? 'Invalid Details Found!' : ''}
                        </span> */}
                    </div>
                </div>
            </form>
        </section>
    )
}
// export default NewUser;